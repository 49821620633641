define("discourse/plugins/discourse-templates/discourse/components/d-templates/tag-drop", ["exports", "@ember/object", "select-kit/components/tag-drop"], function (_exports, _object, _tagDrop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let DTemplatesTagDrop = _exports.default = (_dec = (0, _object.computed)("availableTags.[]"), (_class = class DTemplatesTagDrop extends _tagDrop.default {
    get topTags() {
      // sort tags descending by count and ascending by name
      return (this.availableTags || []).sort((a, b) => {
        if (a.count !== b.count) {
          return b.count - a.count;
        } // descending
        if (a.name !== b.name) {
          return a.name < b.name ? -1 : 1;
        } // ascending
        return 0;
      });
    }
    search(filter) {
      return (this.content || []).map(tag => {
        if (tag.id && tag.name) {
          return tag;
        }
        return this.defaultItem(tag, tag);
      }).filter(tag => {
        if (filter == null) {
          return true;
        }
        const tagFilter = filter.toLowerCase();
        return tag.id.toLowerCase().includes(tagFilter) || tag.name.toLowerCase().includes(tagFilter);
      });
    }
    onChange(tagId, tag) {
      // overrides the action onChange of the parent with the value received in
      // the property onChangeSelectedTag in the handlebars template
      this.onChangeSelectedTag?.(tagId, tag);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "topTags", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "topTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class));
});