define("discourse/plugins/discourse-templates/discourse/connectors/editor-preview/d-templates", ["exports", "@ember/object", "discourse-common/lib/get-owner"], function (_exports, _object, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const SELECTOR_EDITOR_PREVIEW = "#reply-control .d-editor-preview-wrapper > .d-editor-preview";
  var _default = _exports.default = (_obj = {
    setupComponent(args, component) {
      component.setProperties({
        templatesVisible: false,
        model: (0, _getOwner.getOwnerWithFallback)(this).lookup("controller:composer").model
      });
      this.appEvents.on("discourse-templates:show", this, "show");
      this.appEvents.on("discourse-templates:hide", this, "hide");
    },
    teardownComponent() {
      this.appEvents.off("discourse-templates:show", this, "show");
      this.appEvents.off("discourse-templates:hide", this, "hide");
    },
    shouldRender(args, component) {
      return !component.site.mobileView;
    },
    show(_ref) {
      let {
        onInsertTemplate
      } = _ref;
      const elemEditorPreview = document.querySelector(SELECTOR_EDITOR_PREVIEW);
      if (elemEditorPreview) {
        elemEditorPreview.style.display = "none";
      }
      this.set("onInsertTemplate", onInsertTemplate);
      this.set("templatesVisible", true);
    },
    hide() {
      const elemEditorPreview = document.querySelector(SELECTOR_EDITOR_PREVIEW);
      if (elemEditorPreview) {
        elemEditorPreview.style.display = "";
      }
      this.set("templatesVisible", false);
    }
  }, (_applyDecoratedDescriptor(_obj, "show", [_object.action], Object.getOwnPropertyDescriptor(_obj, "show"), _obj), _applyDecoratedDescriptor(_obj, "hide", [_object.action], Object.getOwnPropertyDescriptor(_obj, "hide"), _obj)), _obj);
});